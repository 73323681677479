
@import '../assets/css/scss-variables.scss';

// section:first-of-type .comp-hero {
//   margin-top: var(--hero-half-screen-offset);
// }

.comp-hero {
  display: flex;
  flex-direction: column;
  place-content: center;
  gap: var(--spacer-padding);
  position: relative;

  @media (min-width: $mediaquery-tablet-portrait) {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  @media (min-width: $mediaquery-laptop) {
    grid-column: 2 / span 2;
    grid-row: 1;
  }

  .hero-description {
    width: 100%;
    max-width: 32ch;
    hyphens: none;
    text-align: center;
    margin-inline: auto;
  }

  .preheadline {
    // position: absolute;
    // top: calc(-2em - var(--spacer-padding));
    // top: calc(-2lh - var(--spacer-padding));
    // left: 0;
    // right: 0;
    margin-inline: auto;
    max-width: 20ch;
  }

  .typo-preheadline {
    font-weight: 800;
    letter-spacing: .0125em;
  }
}

.typography-hero {

  p {
    column-gap: 0.2em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  // span {
  //   display: inline-block;
  // }
}

.transition-text-by-word-enter-active,
.transition-text-by-word-leave-active {
  transition:
    transform .25s ease-in-out,
    opacity .25s ease-in-out;
}

.transition-text-by-word-enter,
.transition-text-by-word-leave-to {
  opacity: 0;
  transform: translateY(10%);
}
